import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { close } from "react-icons-kit/fa/close"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper"
import { RichTextRenderer } from "../components/rich-text-renderer"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Video from "../components/video-tw"
import Carousel from "../components/carousel"
import Footer from "../components/Footer.en"

import "swiper/css"
import "swiper/css/pagination"
import "../css/swiper-hack.css"

SwiperCore.use([Navigation])

const MAchineTemplate = ({ location, data }) => {
  const manufacturer = data.webiny.getMachine.data.manufacturer.manufacturer
  const logo = manufacturer.charAt(0).toUpperCase() + manufacturer.substring(1)

  const swiperRef = useRef(null)

  const machine = data.webiny.getMachine.data

  const [overlayOpen, setOverlayOpen] = useState(false)
  const [videoID, setVideoID] = useState("")

  const [windowWidth, setWindowWidth] = useState(
    typeof document !== "undefined" ? document.documentElement.clientWidth : 0
  )
  const setWindow = () => {
    if (typeof document !== "undefined") {
      setWindowWidth(document.documentElement.clientWidth)
    }
  }

  useEffect(() => {
    setWindow()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setWindow)
    }
  }, [])

  const openVideo = id => {
    setVideoID(id)
    setOverlayOpen(true)
  }

  const closeVideo = () => {
    setOverlayOpen(false)
    setVideoID("")
  }

  const getFeaturesTable = tableData => {
    return (
      <>
        {tableData.data.map((feature, i) => {
          return (
            <div key={i}>
              <p className="px-4 block mb-2">{`${feature} ${tableData.value[i]}`}</p>
            </div>
          )
        })}
      </>
    )
  }

  const makeCarousel = (vIds, title) => {
    return (
      <>
        {vIds.length > 1 ? (
          <div className="mx-0 md:mx-12">
            <div className="grid grid-cols-12">
              <div className="col-span-1 place-self-center">
                <button
                  aria-label="slider-left"
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                >
                  <div className="slider-left" />
                </button>
              </div>
              <div className="col-span-10">
                <Swiper
                  slidesPerView={
                    windowWidth >= 768 ? (vIds.length > 3 ? 3 : vIds.length) : 1
                  }
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination-video",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  ref={swiperRef}
                >
                  {vIds.map((id, k) => {
                    return (
                      <SwiperSlide key={k}>
                        <button onClick={evt => openVideo(id)}>
                          <div className="relative text-center">
                            <img
                              src={`https://img.youtube.com/vi/${id}/sddefault.jpg`}
                              alt={title}
                              className="col-span-1"
                            />
                            <GatsbyImage
                              image={getImage(data.videoIcon)}
                              backgroundColor={false}
                              alt="Multilógica"
                              className="bottom-3 left-3"
                              style={{ position: "absolute" }}
                            />
                          </div>
                        </button>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
              <div className="col-span-1 place-self-center">
                <button
                  aria-label="slider-right"
                  onClick={() => swiperRef.current.swiper.slideNext()}
                >
                  <div className="slider-right" />
                </button>
              </div>
            </div>
            <div className="swiper-pagination-video" />
          </div>
        ) : (
          <>
            <div className="text-center">
              <button onClick={evt => openVideo(vIds[0])}>
                <div className="relative text-center">
                  <img
                    src={`https://img.youtube.com/vi/${vIds[0]}/sddefault.jpg`}
                    alt={title}
                    className="max-w-md "
                  />
                  <GatsbyImage
                    image={getImage(data.videoIcon)}
                    backgroundColor={false}
                    alt="Multilógica"
                    className="bottom-3 left-3"
                    style={{ position: "absolute" }}
                  />
                </div>
              </button>
            </div>
          </>
        )}
      </>
    )
  }

  const getText = () => {
    console.log(manufacturer)
    return (
      <>
        {manufacturer === "Mammut" ? (
          <div className="max-w-md">
            <div
              className="text-white text-4xl font-semibold"
              itemProp="headline"
            >
              Productivity
            </div>
            <div className="h-2" />
            <div className="text-white text-xl">
              High production quality combined with a low level of machine
              downtime.
            </div>
            <div className="h-6 " />
            <div
              className="text-white text-4xl font-semibold"
              itemProp="headline"
            >
              Durability
            </div>
            <div className="h-2" />
            <div className="text-white text-xl">
              Robustly designed, extremely durable and ready to use during
              intense 3-shift factory production.
            </div>
          </div>
        ) : (
          <div className="max-w-md">
            <div
              className="text-white text-4xl font-semibold"
              itemProp="headline"
            >
              Quality
            </div>
            <div className="h-2" />
            <div className="text-white text-xl">
              A combination of the expert competence and passionate commitment
              of the staff.
            </div>
            <div className="h-6 " />
            <div
              className="text-white text-4xl font-semibold"
              itemProp="headline"
            >
              Performance
            </div>
            <div className="h-2" />
            <div className="text-white text-xl">
              Innovative and highperforming, but at the same time mature and
              reliable high-tech systems.
            </div>
          </div>
        )}
      </>
    )
  }

  const getCharacteristics = () => {
    if (
      machine.machineCharacteristics &&
      machine.machineCharacteristics !== "null" &&
      machine.machineCharacteristics !== "undefined"
    ) {
      const characteristics =
        machine.machineCharacteristics.split(/\r?\n|\r|\n/g)
      return (
        <>
          <div className="text-4xl font-semibold mb-8 ml-8" itemProp="headline">
            Characteristics
          </div>
          {characteristics.map((c, k) => {
            return (
              <div key={k}>
                {c !== "" ? (
                  <div className="grid grid-cols-12 gap2">
                    <div className="col-span-1">
                      <div
                        className="bg-black m-2"
                        style={{ height: "8px", width: "8px", float: "right" }}
                      />
                    </div>
                    <div className="col-span-11">
                      <p key={k} className="mb-2">
                        {c}
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      <Metatags
        title={`Multilógica | ${machine.menuTitle}`}
        description="Mattress machinery supplier"
        url={`https://newsite.multilogica.ind.br/en/${machine.slug}/`}
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <div
        className={`${
          overlayOpen ? "block" : "hidden"
        } fixed h-screen bg-slate-500 z-40 w-full`}
      >
        <div className="flex justify-end items-start text-white m-1">
          <button aria-label="close-video" onClick={evt => closeVideo()}>
            <Icon size={32} icon={close} />
          </button>
        </div>

        <div id="video-overlay" className=" flex justify-center items-center">
          {videoID !== "" ? (
            <Video
              viddeoUrl={`https://www.youtube.com/embed/${videoID}`}
              endAction={closeVideo}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <NavBar location={location["pathname"]} />
      <article
        className="machine"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="w-full">
          {typeof data.machineImages.edges[0] !== "undefined" ? (
            <GatsbyImage
              image={getImage(data.machineImages.edges[0]["node"])}
              backgroundColor={false}
              alt="mattress machines"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="bg-mi-blue">
          <div className="container mx-auto">
            <div className="h-20" />
            <div className="max-w-xl mx-auto text-center">
              <div
                className="text-white text-4xl font-semibold mx-4"
                itemProp="headline"
              >
                {machine.menuTitle}
              </div>
              <div className="h-2" />
              <div className="text-white text-xl mx-4">{machine.title}</div>
            </div>
            <div className="h-20" />
          </div>
        </div>
        <div className="container mx-auto text-center">
          <div>
            <GatsbyImage
              image={getImage(data[`logo${logo}`])}
              backgroundColor={false}
              alt={`${logo}`}
              className="m-12"
            />
          </div>
          <div>
            {typeof data.machineImages.edges[1] !== "undefined" ? (
              <GatsbyImage
                image={getImage(data.machineImages.edges[1]["node"])}
                backgroundColor={false}
                alt="mattress machines"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          itemProp="articleBody"
          className=" text-xl max-w-6xl mx-4 mt-4 md:mx-auto "
        >
          <RichTextRenderer content={machine.description} />
        </div>
        <div className="h-12" />
        {data.machineImages.edges.slice(3).length > 0 ? (
          <div className="bg-mi-blue">
            <div className="mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-2">
                <div className="col-span-6 m-6 place-self-center">
                  {getText()}
                </div>
                <div
                  className="col-span-6 mb-6 mt-10 mx-auto "
                  style={{ maxWidth: "768px", width: "-webkit-fill-available" }}
                >
                  <Carousel
                    images={data.machineImages.edges.slice(3)}
                    language="en"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="bg-mi-gray">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="col-span-6">
              {typeof data.machineImages.edges[2] !== "undefined" ? (
                <GatsbyImage
                  image={getImage(data.machineImages.edges[2]["node"])}
                  backgroundColor={false}
                  alt="mattress machines"
                  style={{ maxWidth: "768px", height: "500px" }}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="col-span-6 m-8 max-w-2xl">
              {getCharacteristics()}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="col-span-6 text-4xl m-12 font-semibold text-right">
            Technical informations
          </div>
          <div className="col-span-6 mx-8 my-14">
            {getFeaturesTable(machine.technicalData)}
          </div>
        </div>

        {machine.videoIds[0] !== "none" ? (
          <div className="bg-mi-gray">
            <div className="h-12" />
            <div className="text-4xl font-semibold text-center">Videos</div>
            <div className="h-12" />
            <div>{makeCarousel(machine.videoIds, machine.title)}</div>
            <div className="h-12" />
          </div>
        ) : (
          <></>
        )}
      </article>
      <Footer />
    </>
  )
}

export default MAchineTemplate

export const pageQueryEN = graphql`
  query ($entryId: String!, $slug: String!) {
    webiny {
      getMachine(where: { entryId: $entryId }) {
        data {
          menuTitle
          title
          language
          manufacturer {
            manufacturer
          }
          videoIds
          listPosition
          description
          slug
          technicalData {
            data
            value
          }
          machineCharacteristics
        }
      }
    }
    machineImages: allFile(
      filter: { relativePath: { regex: $slug } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED)
          }
        }
      }
    }
    videoIcon: file(relativePath: { eq: "player-button.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 50)
      }
    }
    logoMammut: file(relativePath: { eq: "logos/mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 292)
      }
    }
    logoBeckmann: file(relativePath: { eq: "logos/beckmann.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 212)
      }
    }
  }
`
